import { useEffect, useState } from 'react';
var Emitter = /** @class */ (function () {
    function Emitter(options) {
        var _this = this;
        this.options = options;
        /**
         * Map that holds all registered receiver ids per event type. The ids are kept in a Set.
         * This allows to loop over all listeners per event, and send out the message
         *
         * @example { 'messages.incoming': ['0.wfuyeh', '0.kjhsadf8'] }
         */
        this.receivers = new Map();
        /**
         * Map of all listeners where key is id of the listener and value is an object with callback, id and event name
         *
         * @example { '0.wfuyeh': { callback: (params) => {}, id: '0.wfuyeh', event: 'messages.incoming' } }
         */
        this.listeners = new Map();
        this.cachedMessages = new Map();
        this.send = function (key, params) {
            var _a, _b;
            var receivers = _this.receivers.get(key);
            if (receivers) {
                receivers.forEach(function (receiverId) {
                    var listener = _this.listeners.get(receiverId);
                    if (listener) {
                        listener.callback(params);
                    }
                });
            }
            if ((_b = (_a = _this.options) === null || _a === void 0 ? void 0 : _a.cachedEvents) === null || _b === void 0 ? void 0 : _b.includes(key)) {
                _this.cachedMessages.set(key, params);
            }
        };
        this.listen = function (key, callback) {
            var _a, _b;
            var id = Math.random().toString(16);
            var receivers = _this.receivers.get(key);
            if (((_b = (_a = _this.options) === null || _a === void 0 ? void 0 : _a.cachedEvents) === null || _b === void 0 ? void 0 : _b.includes(key)) && _this.cachedMessages.has(key)) {
                var values = _this.cachedMessages.get(key);
                if (values) {
                    callback(values);
                }
            }
            if (receivers) {
                receivers.add(id);
            }
            else {
                _this.receivers.set(key, new Set().add(id));
            }
            _this.listeners.set(id, {
                id: id,
                event: key,
                callback: callback,
            });
            return {
                id: id,
                event: key,
                clearListener: function () { return _this.clear(id); },
            };
        };
        this.getCache = function (key, fallbackValue) {
            var value = _this.cachedMessages.get(key);
            if (value) {
                return value;
            }
            return fallbackValue;
        };
        this.clear = function (id) {
            var listener = _this.listeners.get(id);
            if (listener) {
                var receivers = _this.receivers.get(listener.event);
                if (receivers) {
                    receivers.delete(id);
                    _this.listeners.delete(id);
                }
            }
            return listener;
        };
        this.clearAll = function () {
            _this.receivers = new Map();
            _this.listeners = new Map();
            return _this.listeners;
        };
        this.clearCache = function (key) {
            _this.cachedMessages.delete(key);
        };
        this.clearAllCache = function () {
            _this.cachedMessages = new Map();
        };
        this.useEventListener = function (key, fallbackValue) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            var _a = useState(_this.getCache(key) || fallbackValue), value = _a[0], setValue = _a[1];
            useEffect(function () {
                var listener = _this.listen(key, setValue);
                return function () {
                    listener.clearListener();
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);
            return value;
        };
        this.options = options || {};
    }
    return Emitter;
}());
export default Emitter;
