/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Manrope_200ExtraLight = require('./Manrope_200ExtraLight.ttf');
export const Manrope_300Light = require('./Manrope_300Light.ttf');
export const Manrope_400Regular = require('./Manrope_400Regular.ttf');
export const Manrope_500Medium = require('./Manrope_500Medium.ttf');
export const Manrope_600SemiBold = require('./Manrope_600SemiBold.ttf');
export const Manrope_700Bold = require('./Manrope_700Bold.ttf');
export const Manrope_800ExtraBold = require('./Manrope_800ExtraBold.ttf');
